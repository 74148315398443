<template>
  <div class="constrain part">
    <div class="row-12">
      <div class="md:col-8 md:offset-2">
        <h2 class="mb-8" v-html="payload.titel" />
        <div class="subtitle text-navy mb-16" v-html="payload.untertitel" />
      </div>
    </div>
    <div class="row-12">
      <div ref="reasons" class="reasons-container md:col-8 md:offset-2" :class="{ 'expand': isExpanded }">
        <div v-for="reason in reasons" :key="reason.id" class="row-12 reason-box">
          <div class="md:col-4">
            <p class="text-navy font-bold mb-2 md:mb-0" v-html="reason.titel" />
          </div>
          <div class="md:col-7 md:offset-1 text-navy pr-4" v-html="reason.beschreibung" />
        </div>
      </div>
      <div class="md:col-8 md:offset-2 flex mt-8">
        <Button :text=" !isExpanded ? payload.weitereAnzeigen : payload.wenigerAnzeigen" @click="toggleHeight" />
      </div>
    </div>
  </div>
</template>

<script>

import Button from '../utils/Button.vue';

export default {
  components: {
    Button,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      isExpanded: false,
      heightTimeout: null,
      height: 0,
    };
  },
  computed: {
    reasons() {
      return this.payload.gruende;
    },
  },
  mounted() {
    this.height = this.$refs.reasons.offsetHeight;
    this.heightTimeout = setTimeout(() => {
      this.$refs.reasons.style.maxHeight = '1000px';
    }, 100);
  },
  unmounted() {
    clearTimeout(this.heightTimeout);
  },
  methods: {
    toggleHeight() {
      if (!this.isExpanded) {
        this.$refs.reasons.style.maxHeight = `${this.height}px`;
        this.isExpanded = !this.isExpanded;
      } else if (this.isExpanded) {
        this.$refs.reasons.style.maxHeight = '1000px';
        this.isExpanded = !this.isExpanded;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.util-btn {
  margin: 0 auto;
}

.reasons-container {
  position: relative;
  overflow: hidden;
  transition: max-height 0.35s ease-out;
  &::before {
    z-index: 5;
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background-image: linear-gradient(transparent 75%, var(--color-light-gray));
    opacity: 1;
    transition: opacity .35s ease-in-out;
  }
  &.expand {
    &::before {
      opacity: 0;
    }
  }
}

.reason-box {
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 2px solid var(--color-light-blue);
}
</style>
